import React, { Component } from 'react';
import { app } from './Firebase'; // Ensure this imports your correctly initialized Firebase app
import { getDatabase, ref, remove, get } from 'firebase/database';
import { GoTrashcan, GoListUnordered } from 'react-icons/go';
import { FaLink } from 'react-icons/fa';
import { navigate } from '@reach/router';

class MeetingsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      starCounts: {}
    };
    this.deleteMeeting = this.deleteMeeting.bind(this);
  }

  componentDidMount() {
    this.fetchStarCounts();
  }

  fetchStarCounts = async () => {
    const db = getDatabase(app);
    const userMeetingsRef = ref(db, `meetings/${this.props.userID}`);

    try {
      const snapshot = await get(userMeetingsRef);
      if (snapshot.exists()) {
        const meetings = snapshot.val();
        const starCounts = {};

        for (const meetingID in meetings) {
          const attendees = meetings[meetingID].attendees;
          if (attendees) {
            let starCount = 0;
            for (const attendeeID in attendees) {
              const starCountRef = ref(
                db,
                `meetings/${this.props.userID}/${meetingID}/attendees/${attendeeID}/starCounts`
              );
              const starCountSnapshot = await get(starCountRef);
              starCount += starCountSnapshot.val() || 0;
            }
            starCounts[meetingID] = starCount;
          } else {
            starCounts[meetingID] = 0;
          }
        }

        this.setState({ starCounts });
      }
    } catch (error) {
      console.error("Error fetching star counts: ", error);
    }
  };

  deleteMeeting = (e, whichMeeting) => {
    e.preventDefault();
    const db = getDatabase(app);
    const meetingRef = ref(db, `meetings/${this.props.userID}/${whichMeeting}`);
    remove(meetingRef)
      .then(() => {
        console.log(`Meeting ${whichMeeting} deleted successfully`);
        this.fetchStarCounts(); // Refresh star counts after deletion
      })
      .catch(error => {
        console.error("Error deleting meeting: ", error);
      });
  };

  render() {
    const { meetings } = this.props;
    const { starCounts } = this.state;
    const myMeetings = meetings.map(item => {
      return (
        <div className="list-group-item d-flex" key={item.meetingID}>
          <section
            className="btn-group align-self-center"
            role="group"
            aria-label="Meeting Options"
          >
            <button
              className="btn btn-sm btn-outline-secondary"
              title="Delete Meeting"
              onClick={e => this.deleteMeeting(e, item.meetingID)}
            >
              <GoTrashcan />
            </button>
            <button
              className="btn btn-sm btn-outline-secondary"
              title="Check In"
              onClick={() =>
                navigate(
                  `/checkin/${this.props.userID}/${item.meetingID}`
                )
              }
            >
              <FaLink />
            </button>
            <button
              className="btn btn-sm btn-outline-secondary"
              title="Attendees List"
              onClick={() =>
                navigate(
                  `/attendees/${this.props.userID}/${item.meetingID}`
                )
              }
            >
              <GoListUnordered />
            </button>
          </section>

          <section className="pl-3 text-left align-self-center">
            {item.meetingName} ({starCounts[item.meetingID] || 0} ⭐)
          </section>
        </div>
      );
    });

    return <div>{myMeetings}</div>;
  }
}

export default MeetingsList;
