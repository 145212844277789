// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getDatabase } from "firebase/database";

// Your Firebase configuration
const config = {
  apiKey: 'AIzaSyA2IQa1_2pwmvxk7aygaLxwmnWKp5GWWFI',
  authDomain: 'easytodo-a8d05.firebaseapp.com',
  databaseURL: 'easytodo-a8d05-default-rtdb.firebaseio.com/',
  projectId: 'easytodo-a8d05',
  storageBucket: 'easytodo-a8d05.appspot.com',
  messagingSenderId: '941406707669 '
};

// Initialize Firebase
const app = initializeApp(config);
const auth = getAuth(app);
const database = getDatabase(app);

// Set up Google Auth provider
const provider = new GoogleAuthProvider();

// Export the necessary components
export { provider, auth, app, database };

//apiKey: 'AIzaSyCk9mIMo7jjWzJ2UYtA8435he3vl1mWdpc',
//authDomain: 'react-spas.firebaseapp.com',
//databaseURL: 'https://react-spas.firebaseio.com',
//projectId: 'react-spas',
//storageBucket: 'react-spas.appspot.com',
//messagingSenderId: '579151738498'