import React, { Component } from 'react';
import { GoTrashcan, GoMail, GoStar } from 'react-icons/go';
import { app } from './Firebase';
import { getDatabase, ref, remove, set, get, push } from 'firebase/database';

class AttendeesList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      starMessage: ''
    };

    this.handleMessageChange = this.handleMessageChange.bind(this);
    this.deleteAttendee = this.deleteAttendee.bind(this);
    this.toggleStar = this.toggleStar.bind(this);
  }

  handleMessageChange(event) {
    this.setState({ starMessage: event.target.value });
  }

  deleteAttendee = (e, whichMeeting, whichAttendee) => {
    e.preventDefault();
    const adminUser = this.props.adminUser;
    const db = getDatabase(app);
    const attendeeRef = ref(
      db,
      `meetings/${adminUser}/${whichMeeting}/attendees/${whichAttendee}`
    );
    remove(attendeeRef)
      .then(() => {
        console.log(`Attendee ${whichAttendee} deleted successfully`);
      })
      .catch(error => {
        console.error("Error deleting attendee: ", error);
      });
  };

  toggleStar = async (e, whichMeeting, whichAttendee) => {
    e.preventDefault();
    const adminUser = this.props.adminUser;
    const db = getDatabase(app);
    const starCountRef = ref(
      db,
      `meetings/${adminUser}/${whichMeeting}/attendees/${whichAttendee}/starCounts`
    );
    const starLogRef = ref(
      db,
      `meetings/${adminUser}/${whichMeeting}/attendees/${whichAttendee}/starLogs`
    );

    try {
      const currentTimestamp = new Date().toISOString();
      const newStarLogRef = push(starLogRef);
      await set(newStarLogRef, { 
        timestamp: currentTimestamp,
        message: this.state.starMessage
      });

      const starCountSnapshot = await get(starCountRef);
      const starCount = starCountSnapshot.val() || 0;
      await set(starCountRef, starCount + 1);

      // Clear the message input after submission
      this.setState({ starMessage: '', messageSubmitted: true });

      console.log(`Star toggled for attendee ${whichAttendee}`);
    } catch (error) {
      console.error("Error toggling star: ", error);
    }
  };

  render() {
    const admin = this.props.adminUser === this.props.userID;
    const attendees = this.props.attendees;
    const myAttendees = attendees.map(item => {
      return (
        <div
          className="col-8 col-sm-6 col-md-4 col-lg-3 mb-2 p-0 px-1"
          key={item.attendeeID}
        >
          <div className="card ">
            <div
              className={
                'card-body px-3 py-2 d-flex align-items-center ' +
                (admin ? '' : 'justify-content-center')
              }
            >
              {admin && (
                <div className="btn-group pr-2">
                  <button
                    className={
                      'btn btn-sm ' +
                      (item.star
                        ? 'btn-info'
                        : 'btn-outline-secondary')
                    }
                    title="Give user a star"
                    onClick={e =>
                      this.toggleStar(
                        e,
                        this.props.meetingID,
                        item.attendeeID
                      )
                    }
                  >
                    <GoStar />
                  </button>
                  <a
                    href={`mailto:${item.attendeeEmail}`}
                    className="btn btn-sm btn-outline-secondary"
                    title="Mail Attendee"
                  >
                    <GoMail />
                  </a>
                  <button
                    className="btn btn-sm btn-outline-secondary"
                    title="Delete Attendee"
                    onClick={e =>
                      this.deleteAttendee(
                        e,
                        this.props.meetingID,
                        item.attendeeID
                      )
                    }
                  >
                    <GoTrashcan />
                  </button>
                </div>
              )}

              <div>{item.attendeeName}</div>
            </div>
            <div className="card-footer">
              <input 
                type="text" 
                className="form-control" 
                placeholder="Enter your message" 
                value={this.state.starMessage} 
                onChange={this.handleMessageChange} 
              />
            </div>
          </div>
        </div>
      );
    });

    return (
      <div className="row justify-content-center">
        {myAttendees}
      </div>
    );
  }
}

export default AttendeesList;
